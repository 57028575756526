import React from 'react';
import notFoundIcon from '../images/404-icon.svg';
import Layout from '../components/layout';
import SEO from '../components/seo';
import NotFoundContent from '../components/NotFoundContent';
import HeroBannerSubpage from '../components/HeroBannerSubpage';

const NotFoundPage = () => (
  <Layout page="404">
    <SEO title="Page not found" description="No such page on Entracker website" keywords={['404']} />
    <HeroBannerSubpage
      title="Page not found"
      subtitle="Page you are looking for has not been found"
      image={notFoundIcon}
    />
    <SEO title="Not found" />
    <NotFoundContent />
  </Layout>
)

export default NotFoundPage
